#question-modal-form .ant-descriptions-item-label {
  width: 150px;
}

.duplicates-header {
  margin-bottom: 12px;
}

.duplicates-headerIcon {
  color: #faad14;
}

.duplicates-header h2,
.duplicates-header p {
  line-height: 1.25em;
  margin: 0;
  padding: 0;
}

.duplicates-headerButtonContainer {
  text-align: right;
}

.duplicatesItems > .ant-collapse-item > .ant-collapse-header {
  padding-bottom: 0;
}

.duplicatesItems > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-bottom: 0;
  padding-top: 0;
}
